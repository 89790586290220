.arabic {
    direction: rtl;
    text-align: right;
    font-size: 16px;
}

.steps {
    width: 100%;
    max-width: 75px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 2px #51bcda;
    font-size: 18px;
    font-weight: bold;
    position: relative;
    padding: 0.25em;
}

.steps:after {
    content: '';
    padding-bottom: 100%;
}

.one-tier {
    background: linear-gradient(0deg, #51bcda 33%, transparent 34%);
    color: #51bcda;
}

.two-tier {
    background: linear-gradient(0deg, #51bcda 66%, transparent 67%);
    color: white !important;
}

.complete {
    background: #51bcda ;
    color: white !important;
}

.infosTitle{
    font-size: 18px;
    font-weight: bold;
}
.infosData{
    font-size: 14px;
}